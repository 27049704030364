import styled from 'styled-components';
import BG from '../../images/foto-pareja-bg.png';

export const MainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 36px;
    height: 100vh;
`;

export const MainBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120%;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        height: 100%;
    }
`;

export const ImageBg = styled.div`
    background: url(${BG}) no-repeat 80% center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
`;

export const MainContent = styled.div`
    max-width: 1200px;
    position: absolute;
    padding: 0 47px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 46px;

    @media screen and (min-width: 768px) {
        align-items: flex-start;
        width: 700px;
    }
    @media screen and (min-width: 1000px) {
        align-items: flex-start;
        width: 1000px;
    }
    @media screen and (min-width: 1200px) {
        align-items: flex-start;
        width: 1200px;
    }
`;

export const MainTxtBg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 0.65;
    padding: 18px 42px;
    position: relative;
    width: 130%;

    @media screen and (min-width: 768px) {
        background: transparent linear-gradient(90deg, #ffffff 0%, #ffffffcc 47%, #ffffff00 100%) 0% 0% no-repeat
            padding-box;
        justify-content: flex-start;
        opacity: 1;
        width: 100%;
        left: -270px;
        padding: 28px 44px;
    }
`;

export const MainText = styled.p`
    color: #333333;
    font-size: 16px;
    font-family: 'Source Sans Pro';
    text-align: center;
    max-width: 280px;
    @media screen and (min-width: 768px) {
        font-size: 18px;
        max-width: 430px;
        position: relative;
        right: -230px;
        text-align: left;
    }
`;

export const MainText2 = styled.p`
    color: #333333;
    font-size: 16px;
    font-family: 'Source Sans Pro';
    text-align: center;
    max-width: 60%;
    @media screen and (min-width: 768px) {
        margin-left: 25px;
        font-size: 18px;
        max-width: 380px;
    }
`;

export const TextBoldBlue = styled.span`
    color: #0060a7;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

export const TextBoldRed = styled.span`
    color: #da291c;
    font-size: 16px;
    font-weight: 600;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

export const TextBoldBalck = styled.span`
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

export const LogoSYRContent = styled.div`
    height: 100%;
    margin-bottom: 58px;
    max-width: 240px;
    @media screen and (min-width: 768px) {
        max-width: 291px;
        margin-bottom: 48px;
        margin-left: 60px;
    }
`;

export const ImgSYR = styled.img`
    width: 100%;
    padding-right: 0;
`;

export const MainButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    @media screen and (min-width: 768px) {
        margin-left: 100px;
    }
`;

export const MainButton = styled.button`
    font-weight: 600;
    border-radius: 5px;
    background: #da291c;
    border: 2px solid #da291c;
    white-space: nowrap;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        border: 2px solid #da291c;
        color: #da291c;
    }
`;

export const MainThanksTxt = styled.p`
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 32px;
    max-width: 60%;
    @media screen and (min-width: 768px) {
        margin-left: 45px;
        margin-top: 24px;
        font-size: 18px;
    }
`;
