import { isMobile } from 'react-device-detect';
import SYR from '../../images/logo-syr.svg';
import {
    ImageBg,
    ImgSYR,
    LogoSYRContent,
    MainBg,
    MainButton,
    MainButtonContainer,
    MainContainer,
    MainContent,
    MainText,
    MainThanksTxt,
    MainTxtBg,
    TextBoldBalck,
    TextBoldBlue,
    TextBoldRed,
} from './MainElements';

const Main = () => {
    /**
     * Redirect to elekra.mx
     */
    const handleClick = () => {
        window.location.replace('https://www.elektra.mx/');
        return null;
    };

    return (
        <MainContainer id="home">
            <MainBg>
                <ImageBg></ImageBg>
            </MainBg>
            <MainContent>
                <LogoSYRContent>
                    <ImgSYR src={SYR} />
                </LogoSYRContent>
                <MainTxtBg>
                    <MainText>
                        Sabemos que es importante para ti seguir contando con el respaldo de aquellos en quienes
                        confías, por eso te informamos que <TextBoldBlue>las tiendas Salinas y Rocha</TextBoldBlue> son
                        parte de <TextBoldRed>Grupo Elektra</TextBoldRed>, y queremos invitarte a ver nuestro{' '}
                        <TextBoldBalck>catálogo en línea de Salinas y Rocha</TextBoldBalck> para que sigas realizando la
                        compra de tus productos favoritos a través de <TextBoldRed>Elektra.mx</TextBoldRed>, o si
                        prefieres, puedes <TextBoldBalck>acudir a cualquiera de nuestras sucursales</TextBoldBalck>,
                        donde nuestros asesores estarán felices de ayudarte.
                    </MainText>
                </MainTxtBg>
                <MainButtonContainer>
                    <MainButton onClick={handleClick}>Quiero ir a Elektra.mx</MainButton>
                </MainButtonContainer>
                {/* {isMobile ? (
                    <MainText2>
                        También puedes acudir a la <TextBoldBalck>tienda</TextBoldBalck> de tu elección
                    </MainText2>
                ) : (
                    <MainText2>
                        También puedes acudir <br />a la <TextBoldBalck>tienda</TextBoldBalck> de tu elección
                    </MainText2>
                )} */}

                {!isMobile ? (
                    <MainThanksTxt>¡Gracias por seguir confiando en nosotros!</MainThanksTxt>
                ) : (
                    <MainThanksTxt>
                        ¡Gracias por seguir <br />
                        confiando en nosotros!
                    </MainThanksTxt>
                )}
            </MainContent>
        </MainContainer>
    );
};

export default Main;
